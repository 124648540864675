const UserRoutes = [
  {
    path: 'users',
    component: () => import('@/views/DefaultRouter'),
    children: [
      {
        path: '',
        name: 'TrafficUsers',
        component: () => import('@/views/Users/TrafficUsers')
      },
      {
        path: 'employee_users',
        name: 'EmployeeUsers',
        component: () => import('@/views/Users/EmployeeUsers')
      },
      {
        path: 'designer_users',
        name: 'DesignerUsers',
        component: () => import('@/views/Users/DesignerUsers')
      }
    ]
  }
]

export default UserRoutes
