import store from '@/store'

export default (to, from, next) => {
  const storageUser = localStorage.getItem('lookbookUser')
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !storageUser &&
    !isAuthenticated
  ) {
    next('/auth/login')
  } else if (
    (to.name === 'Login' && storageUser && isAuthenticated) ||
    (to.name === '/' && storageUser)
  ) {
    return next({ name: 'Dashboard' })
  } else next()
}
