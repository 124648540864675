import CollectionService from '@/services/CollectionService'
import ErrorHandler from '@/helpers/ErrorHandler'
import { FilterPublished, FilterUnPublished } from '@/helpers/filters'
const state = () => ({
  collections: {
    data: []
  },
  looks: []
})

const getters = {
  GetCollections: (state) => {
    return state.collections
  },
  GetPublishedCollections: (state) => {
    let collections = {
      ...state.collections
    }
    let filteredCollections = FilterPublished(collections.data)
    collections.data = filteredCollections
    collections.results = filteredCollections.length
    return collections
  },
  GetUnPublishedCollections: (state) => {
    let collections = {
      ...state.collections
    }
    let filteredCollections = FilterUnPublished(collections.data)
    collections.data = filteredCollections
    collections.results = filteredCollections.length
    return collections
  },
  GetCollectionLooks: (state) => state.looks
}

const mutations = {
  SET_COLLECTION_DATA: (state, payload) => {
    const sortedCollection = payload.data
      .slice()
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    payload.data = sortedCollection
    state.collections = {
      ...payload
    }
  },
  SET_COLLECTION_LOOKS_DATA: (state, payload) => {
    state.looks = payload
  }
}

const actions = {
  FetchCollections: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      const response = await CollectionService.collections(payload)
      context.commit('SET_COLLECTION_DATA', response.data)
    } catch (error) {
      let defaultMessage = 'error fetching collections, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
    }
  },
  FetchCollectionLooks: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      const response = await CollectionService.looks(payload)
      // console.log(JSON.stringify(response.data.data.looks, null, 2))
      context.commit('SET_COLLECTION_LOOKS_DATA', response.data.data.looks)
    } catch (error) {
      let defaultMessage =
        'error fetching looks for this collection, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
    }
  },

  PublishCollection: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      const response = await CollectionService.publish(payload)
      const notification = {
        message: response.data.message,
        status: 'success'
      }
      context.dispatch('notify/add', notification, {
        root: true
      })
      context.dispatch('FetchCollections', {
        designerId: context.rootState.auth.currentUser._id
      })
    } catch (error) {
      let defaultMessage =
        'error updating this collection status, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
    }
  },
  CreateCollections: async (context, payload) => {
    // SET LOADING STATE FOR UI
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      // SEND REQUEST TO API SERVICE FOR CREATING collection
      const createPayload = {
        designer: payload.designer,
        name: payload.name,
        description: payload.description
      }
      const response = await CollectionService.create(createPayload)
      const imagePayload = {
        collection_id: response.data.data._id,
        coverPhoto: payload.image
      }
      await CollectionService.coverUpload(imagePayload)

      // CREATE SUCCESS MESSAGE
      const notification = {
        status: 'success',
        message: 'Successfully created your collection'
      }
      // DISPATCH NOTIFICATION FOR UI
      context.dispatch('notify/add', notification, {
        root: true
      })
      // UPDATE collections WITH NEW DATA BY FETCHING ALL collections AGAIN
      context.dispatch('FetchCollections', {
        designerId: context.rootState.auth.currentUser._id
      })
      // STOP LOADING STATE AFTER REQUEST IS DONE
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
      return 1
    } catch (error) {
      let defaultMessage = 'error creating collection, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
      return 0
    }
  },

  CreateLook: async (context, payload) => {
    // SET LOADING STATE FOR UI
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      // SEND REQUEST TO API SERVICE FOR CREATING collection

      // SEND REQUEST TO API SERVICE FOR CREATING collection
      const createPayload = {
        collection_id: payload.collection_id,
        name: payload.name,
        websiteURL: payload.websiteURL
      }

      const response = await CollectionService.createLook(createPayload)

      const imagePayload = {
        collection_id: payload.collection_id,
        image: payload.image,
        look_id: response.data.data._id
      }

      await CollectionService.lookCoverUpload(imagePayload)

      // CREATE SUCCESS MESSAGE
      const notification = {
        status: 'success',
        message: 'Successfully added a look to your collection'
      }
      // DISPATCH NOTIFICATION FOR UI
      context.dispatch('notify/add', notification, {
        root: true
      })
      location.reload()
    } catch (error) {
      // console.log(JSON.stringify(error, null, 2))
      let defaultMessage =
        'error adding look to a collection, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
    }
  },

  DeleteLook: async (context, payload) => {
    // SET LOADING STATE FOR UI
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      const response = await CollectionService.deleteLook(payload)

      // CREATE SUCCESS MESSAGE
      const notification = {
        status: 'success',
        message: 'Successfully deleted a look of your collection'
      }
      // DISPATCH NOTIFICATION FOR UI
      context.dispatch('notify/add', notification, {
        root: true
      })

      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )

      return response
    } catch (error) {
      let defaultMessage =
        'error adding look to a collection, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
      return 0
    }
  },
  DeleteCollection: async (context, payload) => {
    // SET LOADING STATE FOR UI
    context.commit(
      'loader/SET_REQUEST_LOADER',
      {
        status: true
      },
      {
        root: true
      }
    )
    try {
      await CollectionService.destroy(payload)
      // CREATE SUCCESS MESSAGE
      const notification = {
        status: 'success',
        message: 'Collection deleted successfully.'
      }
      // DISPATCH NOTIFICATION FOR UI
      context.dispatch('notify/add', notification, {
        root: true
      })
      context.dispatch('FetchCollections', {
        designerId: context.rootState.auth.currentUser._id
      })
    } catch (error) {
      let defaultMessage = 'error deleting collection, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        {
          status: false
        },
        {
          root: true
        }
      )
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
