const GalleryRoutes = [
  {
    path: 'gallery',
    component: () => import('@/views/DefaultRouter'),
    children: [
      {
        path: '',
        name: 'Gallery',
        component: () => import('@/views/Gallery/GalleryIndex')
      }
    ]
  }
]

export default GalleryRoutes
