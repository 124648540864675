<template>
  <div>
    <div
      v-if="loading"
      class="w-full mx-auto text-center"
      :style="{ height: '100vh', position: 'relative' }"
    >
      <a-spin class="loader" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped></style>
