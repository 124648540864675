import api from './Api'

export default {
  designer(payload) {
    return api().get(`designers/${payload.designerId}`)
  },
  update(payload) {
    return api().patch(`designers/${payload._id}`, payload)
  },
  publish(payload) {
    return api().patch(`designers/${payload.designerId}/publish`)
  },
  avatar(payload) {
    return api().patch(
      `designers/${payload.designer_id}/profile-img`,
      payload.photo
    )
  },
  coverImage(payload) {
    return api().patch(
      `designers/${payload.designer_id}/uploadCover`,
      payload.photo
    )
  }
}
