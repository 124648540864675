import api from './Api'

export default {
  login(payload) {
    return api().post('designers/auth/login', payload)
  },
  register(payload) {
    return api().post('designers/auth/register', payload)
  },
  verify(payload) {
    return api().post('designers/auth/verify', payload)
  },
  forgotPassword(payload) {
    return api().post('designers/auth/forgot-password', payload)
  }
}
