const state = () => ({
  notifications: []
})

let nextId = 1

const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++
    })
  },
  DELETE(state, notificationToDelete) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToDelete.id
    )
  }
}

const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove({ commit }, notificationToDelete) {
    commit('DELETE', notificationToDelete)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
