<template>
  <a-result title="This Feature is coming soon, work is in progress. !">
    <template #icon>
      <smile-twoTone />
    </template>
    <!-- <template #extra>
      <a-button type="primary">Next</a-button>
    </template> -->
  </a-result>
</template>
<script>
import { SmileTwoTone } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    SmileTwoTone
  }
})
</script>
