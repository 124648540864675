export const FilterPublished = (list) => {
  let publishedList = []
  list.forEach((item) => {
    if (item.published) {
      publishedList.push(item)
    }
  })
  return publishedList
}
export const FilterUnPublished = (list) => {
  let unPublishedList = []
  list.forEach((item) => {
    if (!item.published) {
      unPublishedList.push(item)
    }
  })
  return unPublishedList
}
