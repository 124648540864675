const ErrorHandler = (error) => {
  // console.log(error)
  if (error.response) {
    // let notifications = []
    // error.response.data.messages.forEach((message) => {
    return [
      {
        status: 'error',
        message: error.response.data.error,
        title: 'Request Error'
      }
    ]
    // notifications.push(notification)
    // })
    // return notifications
  } else if (error.request) {
    let notifications = []
    const notification = {
      status: 'error',
      message: 'Slow Network detected, please try again or refresh page.'
    }
    notifications.push(notification)
    return notifications
  } else if (error) {
    let notifications = []
    const notification = {
      status: 'error',
      message: 'Cannot reach server, please try again later.'
    }
    notifications.push(notification)
    return notifications
  }
}

export default ErrorHandler
