import Api from './Api'

export default {
  create(payload) {
    return Api().post('collections', payload)
  },
  coverUpload(payload) {
    return Api().patch(
      `collections/${payload.collection_id}/upload-cover`,
      payload.coverPhoto
    )
  },
  collections(payload) {
    // return Api().get(`collections`)
    return Api().get(`designers/${payload.designerId}/collections`) // correct endpoint
  },
  update(payload) {
    return Api().patch(`collections/${payload._id}`)
  },
  publish(payload) {
    return Api().patch(`collections/${payload.collectionId}/publish`)
  },
  destroy(payload) {
    return Api().delete(`collections/${payload.collectionId}`)
  },

  looks(payload) {
    return Api().get(`collections/${payload}/looks`)
  },

  createLook(payload) {
    return Api().post(`collections/${payload.collection_id}/looks`, payload)
  },

  deleteLook(payload) {
    return Api().delete(
      `collections/${payload.collection_id}/looks/${payload.look_id}/`
    )
  },

  editLook(payload) {
    return Api().patch(
      `collections/${payload.collection_id}/looks/${payload.look_id}/`
    )
  },

  lookCoverUpload(payload) {
    return Api().patch(
      `collections/${payload.collection_id}/looks/${payload.look_id}/upload-image`,
      payload.image
    )
  }
}
