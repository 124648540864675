<template>
  <router-view />
</template>

<script>
export default {
  created() {
    const userString = localStorage.getItem('lookbookUser')
    if (userString && userString !== null) {
      const userData = JSON.parse(userString)
      this.$store.commit('auth/SET_CURRENT_USER', userData)
    }
  }
}
</script>
<style lang="scss"></style>
