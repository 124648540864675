import axios from 'axios'
import store from '@/store'
export default () => {
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL
  })
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch('auth/LogoutUser')
      }
      return Promise.reject(error)
    }
  )
  return api
}
