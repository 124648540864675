const CollectionRoutes = [
  {
    path: 'collections',
    component: () => import('@/views/DefaultRouter'),
    children: [
      {
        path: '',
        name: 'Collections',
        component: () => import('@/views/Collections/CollectionIndex')
      },
      {
        path: ':collectionId/looks',
        name: 'CollectionLooks',
        props: true,
        component: () => import('@/views/Looks/LookIndex')
      }
    ]
  }
]

export default CollectionRoutes
