<template>
  <div v-if="!loading">
    <button
      class="theme__button"
      :class="buttonClass"
      @click.prevent="$emit('buttonAction')"
    >
      <LoadingOutlined class="mr-8" v-if="loading" />
      {{ buttonText }}
      <slot name="icon"></slot>
    </button>
  </div>
  <div v-if="loading">
    <button
      class="theme__button theme__button--disabled"
      :class="buttonClass"
      @click.prevent="$emit('buttonAction')"
      disabled="true"
    >
      <LoadingOutlined class="mr-8" v-if="loading" />
      {{ buttonText }}
      <slot name="icon"></slot>
    </button>
  </div>
</template>

<script>
import { LoadingOutlined } from '@ant-design/icons-vue'
export default {
  components: { LoadingOutlined },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    buttonClass: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['buttonAction'],
  setup() {}
}
</script>

<style lang="scss" scoped></style>
