import { createStore } from 'vuex'
import modules from './modules'
export default createStore({
  state: {
    drawerState: false
  },
  mutations: {
    SET_DRAWER_STATE: (state, payload) => {
      state.drawerState = payload
    }
  },
  actions: {},
  modules
})
