import { createRouter, createWebHistory } from 'vue-router'
import UserRoutes from './UserRoutes'
import GalleryRoutes from './GalleryRoutes'
import CollectionRoutes from './CollectionRoutes'
import AuthRequired from '@/helpers/AuthRequired'
const routes = [
  {
    path: '/',
    component: () => import('@/views/DefaultRouter'),
    redirect: '/auth/login'
  },
  {
    path: '/app',
    component: () => import('@/layouts/DashboardLayout'),
    redirect: '/app/dashboard',
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard/Dashboard')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/Settings/SettingsIndex')
      },
      ...UserRoutes,
      ...GalleryRoutes,
      ...CollectionRoutes
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/Auth'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/Auth/Login')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/Auth/Register')
      },
      {
        path: 'reset_password',
        name: 'ResetPassword',
        component: () => import('@/views/Auth/ResetPassword.vue')
      },
      {
        path: 'verify_account',
        name: 'VerifyAccount',
        component: () => import('@/views/Auth/Verify.vue')
      },
      {
        path: 'forgot_password',
        name: 'ForgotPassword',
        component: () => import('@/views/Auth/ForgotPassword.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/Errors/404')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(AuthRequired)
export default router
