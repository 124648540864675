import DesignerService from '@/services/DesignerService'
import ErrorHandler from '@/helpers/ErrorHandler'
const state = () => ({
  designer: {}
})
const getters = {
  GetDesigner: (state) => state.designer.data
}

const mutations = {
  SET_DESIGNER_STATE: (state, payload) => {
    state.designer = payload
  }
}

const actions = {
  FetchDesigner: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      { status: true },
      { root: true }
    )
    try {
      const response = await DesignerService.designer(payload)
      let totalLooks = 0
      response.data.data.collectionsTotal =
        response.data.data.collections.length
      response.data.data.collections.forEach((collection) => {
        totalLooks += collection.looks.length
      })
      response.data.data.totalLooks = totalLooks
      response.data.data.name = response.data.data.name.toUpperCase()
      context.commit('SET_DESIGNER_STATE', response.data)
    } catch (error) {
      let defaultMessage =
        'error getting designer details, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      //   console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  UpdateDesigner: async (context, payload) => {
    context.commit('loader/SET_FORM_LOADER', { status: true }, { root: true })
    try {
      await DesignerService.update(payload)
      const notification = {
        message: 'designer updated successfully',
        status: 'success'
      }
      context.dispatch('notify/add', notification, { root: true })
    } catch (error) {
      let defaultMessage =
        'error updating designer details, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      //   console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_FORM_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  PublishDesignerProfile: async (context, payload) => {
    context.commit('loader/SET_FORM_LOADER', { status: true }, { root: true })
    try {
      const response = await DesignerService.publish(payload)
      const notification = {
        message: response.data.message,
        status: 'success'
      }
      context.dispatch('notify/add', notification, { root: true })
      context.dispatch('FetchDesigner', { designerId: payload.designerId })
    } catch (error) {
      let defaultMessage =
        'error updating designer details, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      //   console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_FORM_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  UploadProfileAvatar: async (context, payload) => {
    context.commit('loader/SET_FORM_LOADER', { status: true }, { root: true })
    try {
      const response = await DesignerService.avatar(payload)
      const notification = {
        message: response.data.message,
        status: 'success'
      }
      context.dispatch('notify/add', notification, { root: true })
      context.dispatch('FetchDesigner', { designerId: payload.designer_id })
    } catch (error) {
      let defaultMessage =
        'error uploading designer image, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      //   console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_FORM_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  UploadCoverAvatar: async (context, payload) => {
    context.commit('loader/SET_FORM_LOADER', { status: true }, { root: true })
    try {
      const response = await DesignerService.coverImage(payload)
      const notification = {
        message: response.data.message,
        status: 'success'
      }
      context.dispatch('notify/add', notification, { root: true })
      context.dispatch('FetchDesigner', { designerId: payload.designer_id })
    } catch (error) {
      let defaultMessage =
        'error uploading designer cover image, slow network detected'
      let errorResponse = ErrorHandler(error, defaultMessage)
      //   console.log(errorResponse)
      errorResponse.forEach((error) => {
        context.dispatch('notify/add', error, {
          root: true
        })
      })
    } finally {
      context.commit(
        'loader/SET_FORM_LOADER',
        { status: false },
        { root: true }
      )
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
