import AuthService from '@/services/AuthService'
import ErrorHandler from '@/helpers/ErrorHandler'
import axios from 'axios'
import router from '@/router'

const state = () => ({
  currentUser: {},
  registerForm: {}
})

const getters = {
  isAuthenticated: (state) => {
    if (Object.keys(state.currentUser).length) {
      return true
    }
    return false
  },
  GetRegisterForm: (state) => state.registerForm,
  GetCurrentUser: (state) => state.currentUser
}

const mutations = {
  SET_CURRENT_USER: (state, userData) => {
    state.currentUser = userData
    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
    localStorage.setItem('lookbookUser', JSON.stringify(userData))
  },
  CLEAR_CURRENT_USER: () => {
    localStorage.removeItem('lookbookUser')
    location.reload()
  },
  UPDATE_REGISTER_FORM: (state, form) => {
    state.registerForm = Object.assign(state.registerForm, form)
  }
}

const actions = {
  LoginUser: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      { status: true },
      { root: true }
    )
    try {
      const response = await AuthService.login(payload)
      context.commit('SET_CURRENT_USER', response.data.data)
      const notification = {
        message: 'Successfully logged in',
        status: 'success',
        title: 'Login Success'
      }
      context.dispatch('notify/add', notification, { root: true })
      router.push({ name: 'Dashboard' })
    } catch (error) {
      // console.log(error.response || error.request || error)
      // console.log(error.response)
      const errorResponse = ErrorHandler(error)
      errorResponse.forEach((message) => {
        context.dispatch('notify/add', message, { root: true })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  RegisterDesigner: async (context) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      { status: true },
      { root: true }
    )
    const statePayload = context.rootState.auth.registerForm
    const payload = {
      name: statePayload.firstname + ' ' + statePayload.lastname,
      brandName: statePayload.brandName,
      email: statePayload.email,
      phone: `+${statePayload.code}${statePayload.phone}`,
      password: statePayload.password,
      description: statePayload.description,
      companySite: statePayload.websiteUrl,
      facebook: statePayload.facebookUrl,
      twitter: statePayload.twitterUrl,
      instagram: statePayload.instagramUrl,
      website_URL: statePayload.websiteType
    }
    try {
      await AuthService.register(payload)
      const notification = {
        message: 'Successfully created your account',
        status: 'success',
        title: 'Account Success'
      }
      context.dispatch('notify/add', notification, { root: true })
      return 1
    } catch (error) {
      // console.log(error.response || error.request || error)
      // console.log(error.response)
      const errorResponse = ErrorHandler(error)
      errorResponse.forEach((message) => {
        context.dispatch('notify/add', message, { root: true })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  VerifyUserMail: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      { status: true },
      { root: true }
    )
    try {
      await AuthService.verify(payload)
      const notification = {
        message: 'Successfully verified your email',
        status: 'success',
        title: 'Email Verification'
      }
      context.dispatch('notify/add', notification, { root: true })
      router.push({ name: 'Login' })
    } catch (error) {
      // console.log(error.response || error.request || error)
      // console.log(error.response)
      const errorResponse = ErrorHandler(error)
      errorResponse.forEach((message) => {
        context.dispatch('notify/add', message, { root: true })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  forgotPassword: async (context, payload) => {
    context.commit(
      'loader/SET_REQUEST_LOADER',
      { status: true },
      { root: true }
    )
    try {
      const response = await AuthService.forgotPassword(payload)
      console.log(response)
      const notification = {
        message: 'Reset email has been sent successfully',
        status: 'success',
        title: 'Password Recovery Success'
      }
      context.dispatch('notify/add', notification, { root: true })
    } catch (error) {
      const errorResponse = ErrorHandler(error)
      errorResponse.forEach((message) => {
        context.dispatch('notify/add', message, { root: true })
      })
    } finally {
      context.commit(
        'loader/SET_REQUEST_LOADER',
        { status: false },
        { root: true }
      )
    }
  },
  LogoutUser: async (context) => {
    context.commit('CLEAR_CURRENT_USER')
  },
  UpdateRegisterForm: (context, payload) => {
    context.commit('UPDATE_REGISTER_FORM', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
