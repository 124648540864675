const state = {
  requestLoader: false,
  tableLoader: false,
  formLoader: false
}
const getters = {}

const mutations = {
  SET_REQUEST_LOADER(state, loadingStatus) {
    state.requestLoader = loadingStatus.status
  },
  SET_FORM_LOADER(state, loadingStatus) {
    state.formLoader = loadingStatus.status
  },
  SET_TABLE_LOADER(state, loadingStatus) {
    state.tableLoader = loadingStatus.status
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
