import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AppButton from '@/components/base/AppButton.vue'
import AppLoader from '@/components/base/AppLoading.vue'
import ComingSoon from '@/components/common/ComingSoon.vue'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import 'ant-design-vue/dist/antd.css'
import '@/assets/scss/global.scss'

const app = createApp(App).use(store).use(router).use(Antd)

app.component('AppButton', AppButton)
app.component('AppLoader', AppLoader)
app.component('ComingSoon', ComingSoon)
app.use(VueTelInput)
// mount application
app.mount('#app')
